<template>
  <div class="text-page container py-5">
    <BaseHeading>
      Informácie o ochrane osobných údajov
    </BaseHeading>
    <div class="content">
      <p>
        Ochrana Vášho súkromia a Vašich osobných údajov patrí medzi naše priority, ubezpečujeme Vás, že Vaše osobné údaje spracovávame v súlade s platnou právnou úpravou, predovšetkým v súlade s Nariadením Európskeho parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov (ďalej len „Nariadenie“). Kladieme dôraz na najvyššiu možnú mieru zabezpečenia, dbáme na riadny výber zmluvných sprostredkovateľov a striktné dodržiavanie všetkých pravidiel, ktorými sme viazaní. Všetky osoby, ktoré prídu do styku s osobnými údajmi pri plnení svojich pracovných alebo zmluvných povinností, sú viazané povinnosťou mlčanlivosti ako o osobných údajoch, tak i o prijatých bezpečnostných opatreniach, pričom táto povinnosť trvá neobmedzene i po skončení pracovného pomeru alebo zmluvného vzťahu.
      </p>
      <h2>
        Prečítajte si viac:
      </h2>
      <ul>
        <li><a href="./docs/osobne-udaje-klienti.pdf" target="_blank">Informácie o spracúvaní osobných údajov pre klientov</a></li>
        <li><a href="./docs/osobne-udaje-agenti-verejnost.pdf" target="_blank">Informácie o spracúvaní osobných údajov pre agentov a verejnosť</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import BaseHeading from '@/components/BaseHeading.vue';

export default {
  components: {
    BaseHeading,
  },
};
</script>
